import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Index from "../views/index.vue";
import Fence from "../views/setFence.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/index",
    name: "index",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Index,
  },
  {
    path: "/basicManage",
    name: "basicManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/basicManage.vue"),
  },
  {
    path: "/schoolManage",
    name: "schoolManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schoolManage.vue"),
  },
  {
    path: "/gradeManage",
    name: "gradeManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/gradeManage.vue"),
  },
  {
    path: "/classManage",
    name: "classManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/classManage.vue"),
  },
  {
    path: "/teacherManage",
    name: "teacherManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teacherManage.vue"),
  },
  {
    path: "/studentManage",
    name: "studentManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/studentManage.vue"),
  },
  {
    path: "/deviceManage",
    name: "deviceManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/deviceManage.vue"),
  },
  {
    path: "/teacherApply",
    name: "teacherApply",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teacherApply.vue"),
  },
  {
    path: "/signManage",
    name: "signManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/signManage.vue"),
  },
  {
    path: "/studentApply",
    name: "studentApply",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/studentApply.vue"),
  },
  {
    path: "/leaveManage",
    name: "leaveManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/leaveManage.vue"),
  },
  {
    path: "/noticeManage",
    name: "noticeManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/noticeManage.vue"),
  },
  {
    path: "/setFence",
    name: "addFence",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: Fence,
  },
  {
    path: "/setFence/:id",
    name: "setFence",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: Fence,
  },
  {
    path: "/noticeCenter",
    name: "noticeCenter",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/noticeCenter.vue"),
  },
  {
    path: "/noticeCenter/:id",
    name: "noticeCenterSos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/noticeCenter.vue"),
  },
  // {
  //   path: '/map',
  //   name: 'Map',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/map.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
