<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <!-- <div style="margin-left: 190px">
        <span style="font-size: 16px">全校人数:</span> {{schoolStudents}}<span
          style="font-size: 16px"
          >人</span
        >
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">学生卡数:</span> {{schoolDevices}}<span
          style="font-size: 16px"
          >人</span
        >
      </div> -->
      <!-- <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> 99</span
        ><span style="font-size: 16px">%</span>
      </div> -->
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <!-- <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px"
      /> -->
      <!-- <div class="detailRight" style="position: absolute; right: 40px">
        允许上级单位查看本校学生智慧卡相关信息
        <el-switch
          style="margin-left: 10px; margin-top: 2px"
          v-model="allow"
          active-color="#5D9CFF"
          inactive-color="#a5a5a5"
        >
        </el-switch>
      </div> -->
      <div class="details">
        基础信息<img
          src="../assets/ico_you.png"
          style="margin-top: 2px"
        />校园信息<img
          src="../assets/ico_you.png"
          style="margin-top: 2px"
        />安全围栏
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img
          :src="
            schoolData.schoolPhotoUrl == ''
              ? require('../assets/school.png')
              : schoolData.schoolPhotoUrl
          "
          style="margin-left: 23px; width: 115px; height: 115px"
        />
      </div>
      <div class="item" @click="$router.push('/index')">
        <img
          src="../assets/ico_shouye.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div
        class="item"
        style="
          background: #f5f6fa;
          color: #5d9cff;
          border-bottom: 1px solid #b9d5fa;
        "
        @click="$router.push('/basicManage')"
      >
        <div style="display: flex; align-items: center">
          <img
            style="margin-right: 10px"
            src="../assets/ico_jichuguanli_blue.png"
          />基础管理
        </div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/schoolManage')"
      >
        <div style="display: flex; align-items: center">校区管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/gradeManage')"
      >
        <div style="display: flex; align-items: center">年级管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/classManage')"
      >
        <div style="display: flex; align-items: center">班级管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/teacherManage')"
      >
        <div style="display: flex; align-items: center">教师管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/studentManage')"
      >
        <div style="display: flex; align-items: center">学生管理</div>
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />教师申请
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />学生申请
      </div>
      <div class="item" @click="$router.push('/deviceManage')">
        <img
          style="margin-right: 10px"
          src="../assets/devices_white.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />学校公告
      </div>
    </div>
    <div class="content">
      <div class="card" style="width: 1700px; height: 720px">
        <div class="inner">
          <div style="font-size: 18px; font-weight: 500; color: #666666">
            安全围栏
          </div>
          <div class="text-small" style="margin-top: 24px">
            围栏名称：<el-input
              v-model="fenceName"
              style="width: 400px"
              placeholder="请输入围栏名称"
            ></el-input>
          </div>
          <div class="text-small">
            地理位置：<v-distpicker
              style="width: 400px"
              :province="province"
              :city="city"
              :area="area"
              @selected="addressChange"
            ></v-distpicker>
          </div>
          <div class="text-small">
            详细地址：<el-input
              v-model="detail"
              style="width: 400px"
              placeholder="请输入详细地址"
            ></el-input>
            <el-button
              @click="getLocation"
              style="position: absolute; left: 410px"
              >定位</el-button
            >           
          </div>
          <div class="details" style="font-size: 12px; margin-left: 82px">选择地理位置并填写详细位置后，点击定位确认围栏位置。</div>
          <div class="text-small">
            安全半径：<el-slider
              v-model="range"
              show-input
              style="width: 400px"
              :min="100"
              :max="5000"
              :step="10"
            >
            </el-slider>
          </div>
          <el-button
            style="
              position: absolute;
              width: 200px;
              left: 140px;
              margin-top: 50px;
            "
            type="primary"
            @click="submit"
            :disabled="!positionChecked"
            >确定</el-button
          >
          <div class="map-container" :style="styleObject">
            <div class="amap-wrapper" :style="styleObject">
              <el-amap
                class="amap-box"
                :vid="'amap-vue'"
                zoom="18"
                :plugin="plugins"
                :zooms="[3, 20]"
                :center="center"
                :expandZoomRange="true"
                v-if="center.length > 0 && loaded"
              >
                <el-amap-circle
                  v-for="(circle, index) in circles"
                  :center="center"
                  :radius="range"
                  :key="index"
                  fill-opacity="0.2"
                  strokeColor="#38f"
                  strokeOpacity="0.8"
                  strokeWeight="1"
                  fillColor="#38f"
                ></el-amap-circle>
              </el-amap>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="showErrorAlert" width="30%">
      <span>{{ errorMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showErrorAlert = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "setFence",
  components: {},
  data() {
    let self = this;
    return {
      loaded: false,
      positionChecked: false,
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      mapType: "class",
      allow: false,
      placeSet: false,
      address: "",
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      managerSet1: false,
      managerSet2: false,
      timeMorning1: false,
      timeMorning2: false,
      geocoder: undefined,
      checkboxGroup1: [],
      checkboxGroup2: [],
      plugins: [
        {
          //地图工具条
          pName: "ToolBar",
          events: {
          },
        },
        {
          //地图工具条
          pName: "Geolocation",
          events: {
            init(o) {
              if (self.$route.params.id != undefined) return;
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.center = [result.position.lng, result.position.lat];
                }
              });
            },
          },
        },
      ],
      circles: [{ center: [116.397451, 39.909187], radius: this.range }],
      fenceName: "",
      detail: "",
      wholeDetail: "",
      fullAddress: "",
      province: "",
      city: "",
      area: "",
      styleObject: {
        width: "1160px",
        height: "680px",
      },
      center: [116.397451, 39.909187],
      range: 100,
      fence: {},
      errorMessage: "",
      showErrorAlert: false,
    };
  },
  mounted() {
    // this.drawChart();
    // this.drawChart1();
    // this.drawChart2();
    // this.drawChartLeft1();
    console.log(this.schoolData);
    setTimeout(() => {
      this.geocoder = new window.AMap.Geocoder();

      console.log(this.$route.params.id);
      if (this.$route.params.id != undefined) {
        this.$http
          .get(`${this.$baseUrl}/enclosure/findById/${this.$route.params.id}`)
          .then((response) => {
            this.loaded = true;
            this.fence = response.data.result;
            this.range = response.data.result.radius;
            this.wholeDetail = response.data.result.enclosureAddress;
            this.detail = response.data.result.enclosureAddress.split(',')[response.data.result.enclosureAddress.split(',').length-1];
            this.province = response.data.result.enclosureAddress.split(',')[0];
            this.city = response.data.result.enclosureAddress.split(',')[1];
            this.area = response.data.result.enclosureAddress.split(',')[2];
            this.fenceName = response.data.result.enclosureName;
            this.center = [
              response.data.result.srcLongitude,
              response.data.result.srcLatitude,
            ];
            this.circles[0].center = this.center;
          });
      } else {
        this.loaded = true;
      }
    }, 500);
  },
  methods: {
    submit() {
      if (this.fenceName.length < 1) {
        this.errorMessage = "请输入围栏名称";
        this.showErrorAlert = true;
        return;
      }
      if (this.$route.params.id != undefined) {
        this.fence.radius = this.range;
        this.fence.enclosureAddress = this.wholeDetail;
        this.fence.enclosureName = this.fenceName;
        this.fence.longitude = this.center[0];
        this.fence.latitude = this.center[1];
        this.$http
          .put(`${this.$baseUrl}/enclosure/update`, this.fence)
          .then((response) => {
            if (response.data.code != 200) {
              this.errorMessage = response.data.message;
              this.showErrorAlert = true;
              return;
            }
            this.$router.replace("/basicManage");
          });
      } else {
        this.fence.radius = this.range;
        this.fence.enclosureAddress = this.wholeDetail;
        this.fence.enclosureName = this.fenceName;
        this.fence.longitude = this.center[0];
        this.fence.latitude = this.center[1];
        this.fence.enclosureType = "1";
        this.fence.schoolId = this.schoolData.schoolId;
        this.$http
          .post(`${this.$baseUrl}/enclosure/add`, this.fence)
          .then((response) => {
            if (response.data.code != 200) {
              this.errorMessage = response.data.message;
              this.showErrorAlert = true;
              return;
            }
            this.$router.replace("/basicManage");
          });
      }
    },
    addressChange(data) {
      this.fullAddress =
        data.province.value + ',' + data.city.value + ',' + data.area.value + ',';
      // this.detail = this.fullAddress;
      console.log(this.fullAddress);
    },
    getLocation() {
      const checkAddress = this.fullAddress == "" ? this.wholeDetail : this.fullAddress + this.detail;
      this.wholeDetail = checkAddress;
      console.log(checkAddress);
      this.geocoder.getLocation(checkAddress, (status, result) => {
        if (status === "complete" && result.geocodes.length) {
          this.positionChecked = true;
          console.log(
            result.geocodes[0].location.lng,
            result.geocodes[0].location.lat
          );
          this.center = [
            result.geocodes[0].location.lng,
            result.geocodes[0].location.lat,
          ];
        } else {
          this.errorMessage = "根据地址查询位置失败，请完善地址信息";
          this.showErrorAlert = true;
          console.log("根据地址查询位置失败");
        }
      });
    },
  },
};
</script>

<style scoped>
.confirm {
  position: absolute;
  width: 116px;
  right: 20px;
  top: 0;
}
.inner >>> select {
  max-width: 130px;
}
.item-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
  background: #f5f6fa;
}
.item-white:hover {
  background: #d2e3ff;
}

.right_items {
  margin-bottom: 16px;
  width: 414px;
  position: relative;
}
.inner {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  position: relative;
  text-align: left;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.text-small {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 16px;
  display: flex;
  align-items: center;
  position: relative;
}
.card {
  margin: 10px;
  background-color: white;
  width: 550px;
  height: 275px;
  border-radius: 9px;
}

.detailRight {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 170px);
  position: relative;
  top: 170px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}
.map-container {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 9px;
  width: 1160px;
  height: 840px;
  overflow: hidden;
}

.amap-wrapper {
  border-radius: 9px;
  width: 1160px;
  height: 840px;
  overflow: hidden;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}
</style>