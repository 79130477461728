const CryptoJS = require('crypto-js');


function Decrypt(word, key) {
    let ckey = CryptoJS.enc.Utf8.parse(key);
    let decrypt = CryptoJS.AES.decrypt(word, ckey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString();
}

function Encrypt(word, key) {
    let ckey = CryptoJS.enc.Utf8.parse(key);
    let encrypted = CryptoJS.AES.encrypt(word, ckey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString();
}


export default {
    Decrypt,
    Encrypt
}